<template>
  <div class="signup_from">
    <input
      class="input_text margin_top19"
      type="text"
      placeholder="输入用户名，支持汉字，字母与数字，4-14位"
      v-model.trim="userName"
      @focus="clearErrType"
      :class="[ errorType == 1 ? 'err_border' : '']"
      maxlength="14"
    />
    <input
      class="input_text margin_top19"
      type="text"
      placeholder="输入手机号码"
      @input ="phoneNum=phoneNum.replace(/[^\d]/g,'')"
      maxlength="11"
      :class="[ errorType == 2 ? 'err_border' : '']"
      v-model.trim="phoneNum"
      @focus="clearErrType"
    />
    <div class="login_code_box margin_top19">
      <input
        class="input_text input_num"
        type="text"
        maxlength="6"
        placeholder="输入验证码"
        :class="[ errorType == 3 ? 'err_border' : '']"
        v-model.trim="code"
        @focus="clearErrType"
      />
      <div v-if="!msgCode" class="code_btn cursor_hover" @click="getMsgCode">发送验证码</div>
      <div v-if="msgCode" class="code_btn code_send_active">已发送({{timer}}s)</div>
    </div>
    <div class="margin_top19 rule">
      <span
        @click="ruleCheck"
        :class="[ruleRead ? 'rule_check_active' : '']"
        class="rule_check border_radius4 iconfont icon-fangxingxuanzhongfill"
      ></span>
      <span class="rule_text">我已阅读并接受<i @click="toDocument(1)">《用户协议》</i>和<i @click="toDocument(2)">《隐私协议》</i></span
      >
    </div>
    <div class="subimt_btn cursor_hover" :style="{background: ruleRead ? '#1C8EEB' : '#dddddd'}" @click="subimtInfo">注册并登录</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msgCode: false,
      timer: 60, //定义初始时间为60s

      ruleRead: false,
      errorType: 0,
      userName: "",
      phoneNum: "",
      code: "",
    };
  },
  props:{
    wxData: {
      wechatId: "",
      wechatName: "",
      userImg: "",
    }
  },
  methods: {
    clearErrType(){
      this.errorType = 0;
    },
    backIndex(){
      this.$router.push({
        path: "/"
      })
    },
    ruleCheck() {
      this.ruleRead = !this.ruleRead;
    },
    //  注册信息
    async subimtInfo(){
      if(!this.ruleRead) return;
      if(!this.$verify.userName(this.userName)) {
        this.errorType = 1;
        return this.$YTmsg('请填写正确的用户名', "error");
      }
      if(!this.$verify.phone(this.phoneNum)) {
        this.errorType = 2;
        return this.$YTmsg('请填写正确的手机号', "error");
      }
      if(!this.$verify.code(this.code)) {
        this.errorType = 3;
        return this.$YTmsg('请填写正确的验证码', "error");
      }

      this.errorType = 0;
      if(this.errorType) return;

      let res = await this.$axios.post('/portal/yt/unauth/member/register',{
        userName: this.userName,
        mobile: this.phoneNum,
        captcha: this.code,
        type: '2',
        wechatId: this.$props.wxData.wechatId,
        wechatName: this.$props.wxData.wechatName,
        userImg: this.$props.wxData.userImg,
      });
      if(res.token){
        window.localStorage.setItem("token", res.data.accessToken);
        // window.localStorage.setItem("userName", res.data.userName || "");
        window.localStorage.setItem("userId", res.data.userId);
        this.$router.push("/");
      }else{
        // window.localStorage.clear();
        this.$YTmsg( res.message || "注册失败", 'warning');
      }
      
    },
    //  微信登录
    WXLogin(){
      this.$emit('WXLogin');
    },
    bindToLogin(){
      this.$emit('bindToLogin', 1);
    },
    toDocument(index){
      // let routeData = this.$router.resolve({ 
      //   path: index == 1 ? '/document/user' : '/document/privacy'
      // });
      // window.open(routeData.href, '_blank');
    },
    //  注册验证码登录
    async getMsgCode() {
      if(!this.$verify.phone(this.phoneNum)) return this.$YTmsg('请填写正确的手机号', "error");

      //  注册验证码登录
      let res = await this.$axios.get('/portal/yt/unauth/member/sendSms/register?mobile='+this.phoneNum);
      if(res.code && res.code != 0){
        return this.$YTmsg(res.message || "验证码发送失败", "error");
      }
      this.$YTmsg("验证码已发送,请注意查收", "success");

      if (this.msgCode) return;
      this.msgCode = true;

      let that = this;
      let auth_timer = setInterval(() => {
        this.timer--; //递减时间
        if (this.timer <= 0) {
          that.msgCode = false;
          that.timer = 60;
          clearInterval(auth_timer);
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.signup_from {
  font-size: 14px;
  i {
    font-style: normal;
  }
  .title {
    color: #000;
    font-size: 22px;
    text-align: center;
    margin-bottom: 10px;
  }
  .rule{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .rule_check{
    color:#dddddd;
    cursor: pointer;
    padding-top: 3px;
  }
  .rule_check_active{
    color:#1C8EEB;
  }
  .rule_text{
    color: #333333;
    margin-left: 8px;
    i{
      color:#1C8EEB;
      cursor: pointer;
    }
  }

  .signup_bottom_text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }
}

.input_text {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border: #dddddd 1px solid;
  padding-left: 15px;
  outline: none;
}
.input_num {
  width: 200px;
}

.margin_top19 {
  margin-top: 19px;
}
.login_code_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.code_btn {
  width: 126px;
  line-height: 46px;
  text-align: center;
  border: #1C8EEB 1px solid;
  color: #1C8EEB;
  border-radius: 4px;
  box-sizing: border-box;
}

.subimt_btn {
  width: 100%;
  line-height: 52px;
  background-color: #1C8EEB;
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 4px;
  margin-top: 30px;
}
.signup_now {
  text-align: center;
  margin-top: 24px;
  color: #1C8EEB;
  font-size: 14px;
}
.third_party_login {
  margin: 32px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    width: 110px;
    height: 1px;
    background-color: #ddd;
    margin-top: 1px;
  }
}


</style>