<template>
  <div class="retrieve_from">
    <div class="title">重置密码</div>
    <div class="ico_input">
      <div class="ico_box">
        <i class="hy-icon-shouji"></i>
      </div>
      <input
        style="height: 48px"
        class="input_text margin_top19"
        @input ="phoneNum=phoneNum.replace(/[^\d]/g,'')"
        placeholder="输入手机号码"
        maxlength="11"
        :class="[ errorType == 1 ? 'err_border' : '']"
        v-model.trim="phoneNum"
        @focus="clearErrType"
      />
    </div>
    <div class="login_code_box margin_top19">
      <div class="ico_input">
        <div style="top:16px" class="ico_box">
          <i class="hy-icon-yanzhengma"></i>
        </div>
        <input
          class="input_text input_num"
          type="text"
          placeholder="输入验证码"
          maxlength="6"
          :class="[ errorType == 2 ? 'err_border' : '']"
          v-model.trim="code"
          @focus="clearErrType"
        />
      </div>
      <div v-if="!msgCode" class="code_btn cursor_hover" @click="getMsgCode">发送验证码</div>
      <div v-if="msgCode" class="code_btn code_send_active">已发送({{timer}}s)</div>
    </div>
     <div class="ico_input">
      <div class="ico_box">
        <i class="hy-icon-mima"></i>
      </div>
      <input
        class="input_text margin_top19"
        type="password"
        placeholder="密码必须为8-16位字母大小写及数字组合"
        maxlength="16"
        v-model.trim="password"
        @focus="clearErrType"
        :class="[ errorType == 3 ? 'err_border' : '']"
      />
     </div>
     <div class="ico_input">
      <div class="ico_box">
        <i class="hy-icon-mima"></i>
      </div>
      <input
        class="input_text margin_top19"
        type="password"
        maxlength="20"
        placeholder="再次输入密码"
        :class="[ errorType == 4 ? 'err_border' : '']"
        v-model.trim="password_confirm"
        @focus="clearErrType"
      />
     </div>
    <div class="subimt_btn" @click="subimtInfo">重置</div>
    <div class="gohome"><span @click="backSignup(1)" class="activer">去登录</span> | <span @click="backSignup(2)" class="activer">免费注册</span></div>
  </div>
</template>
<script>
import { getsendSmsCode,userForgetPassword } from '@/api/home.js'
import md5 from "js-md5";
export default {
  data() {
    return {
      msgCode: false,
      timer: 60, //定义初始时间为60s

      errorType: 0,
      phoneNum: "",
      code: "",
      password: "",
      password_confirm: ""
    };
  },
  methods: {
    clearErrType(){
      this.errorType = 0;
    },
    backIndex(){
      this.$router.push({
        path: "/"
      })
    },
    bindToLogin(){
      this.$emit('toLogin', 1);
    },
    // 手机号校验
    // isPhoneAvailable(e, index) {
    //   let res = this.$verify.phone(e.target.value);

    //   res
    //     ? (this.errorType = 0)
    //     : ((this.errorType = index),
    //       this.$YTmsg( "请输入正确的手机号", "error"));
    // },
    // 验证码校验
    // isCodeAvailable(e, index){
    //   let res = this.$verify.code(e.target.value);

    //   res
    //     ? (this.errorType = 0)
    //     : ((this.errorType = index),
    //       this.$YTmsg( "验证码只能是6位数字", 'error'));
    // },
    // 密码校验
    // isPasswordAvailable(e, index){
    //   let res = this.$verify.password(e.target.value);

    //   res
    //     ? (this.errorType = 0)
    //     : ((this.errorType = index),
    //       this.$YTmsg("密码为6-20位，数字/字母组合", 'error'));
    // },
    // 确认密码
    // confirmPassword(e, index){
    //   if(e.target.value != this.password){
    //     this.$YTmsg("两次输入的密码不一致", 'error');
    //       this.errorType = index;
    //       return;
    //   }else{
    //     this.errorType = 0;
    //   }
    // },
    //    获取短信验证码
    async getMsgCode() {
      
      if(!this.$verify.phone(this.phoneNum)) {
        this.errorType = 1;
        return this.$YTmsg('请填写正确的手机号', "error");
      }

      //  发送重置密码短信验证码
      let res =  await getsendSmsCode({phone:this.phoneNum});
      if(res.code && res.code != 0){
        return this.$YTmsg(res.message || "验证码发送失败", "error");
      }
      this.$YTmsg("验证码已发送,请注意查收", "success");

      if (this.msgCode) return;
      this.msgCode = true;

      let auth_timer = setInterval(() => {
        this.timer--; //递减时间
        if (this.timer <= 0) {
          this.msgCode = false;
          this.timer = 60;
          clearInterval(auth_timer);
        }
      }, 1000);
    },
    //  找回密码数据提交
    async subimtInfo(){
      if(!this.$verify.phone(this.phoneNum)) {
        this.errorType = 1;
        return this.$YTmsg('请填写正确的手机号', "error");
      }
      if(!this.$verify.code(this.code)) {
        this.errorType = 2;
        return this.$YTmsg('请填写正确的验证码', "error");
      }
      if(!this.$verify.password(this.password)) {
        this.errorType = 3;
        return this.$YTmsg('密码必须为8-16位字母大小写及数字组合', "error");
      }
      if(this.password !== this.password_confirm) {
        this.errorType = 3;
        return this.$YTmsg('两次密码输入不一致', "error");
      }
      this.errorType = 0;

      if(this.errorType) return;
      let res = await userForgetPassword({
        'grant_type': 'captcha',
        'clientId': 'xjcec',
        'clientSecret': 'xjcec_secret',
        'scope': 'all',
        telephone: this.phoneNum,
        password: md5(this.password),
        smsCode: this.code,
        againPassword: md5(this.password),
      });
      if(res.code && res.code != 0){
        return this.$YTmsg(res.message || "密码重置失败", "error");
      }else{
        this.$YTmsg("密码重置成功", "success");
        // this.$router.push({
        //   path: "/login",
        //   query:{
        //     key: 1
        //   }
        // });
        this.$emit('toLogin', 1);
      }
    },
    //  跳转立即注册
    backSignup(i){
      this.$emit('toLogin', i);
    }
  },
};
</script>
<style lang="scss">
.activer {
  cursor: pointer;
  &:hover {
    color: #1C8EEB;
  }
}
.gohome {
  height: 16px;
  line-height: 16px;
  margin-top: 30px;
  text-align: right;
}
.ico_input {
  position: relative;
  .ico_box {
    position: absolute;
    top: 36px;
    left: 12px;
  }
}
.retrieve_from {
  .title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 400;
    color: #1C8EEB;
  }

  .signup_btn {
    color: #1C8EEB;
    text-align: center;
    cursor: pointer;
  }
  i {
    font-style: normal;
  }
}

.input_text {
  width: 100%;
  height: 48px;
  border-radius: 2px;
  border: #dddddd 1px solid;
  padding-left: 38px;
  outline: none;
}

.input_num {
  width: 200px;
  height: 48px !important;
}

.margin_top19 {
  margin-top: 19px;
}

.login_code_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.code_btn {
  width: 126px;
  line-height: 46px;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 14px;
  background: linear-gradient(90deg, #2079FB, #4CB2FC);
  border-radius: 2px;
}

.subimt_btn {
  width: 100%;
  line-height: 52px;
  background: linear-gradient(90deg, #2079FB, #4CB2FC);
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
  margin-top: 30px;
  cursor: pointer;
}
.signup_bottom_text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    font-size: 14px;
  }
</style>