<template>
  <div>
    <div class="signup_from">
      <div class="title">用户注册</div>
      <div class="ico_input">
        <div class="ico_box">
          <i class="hy-icon-shouji"></i>
        </div>
        <input
          class="input_text margin_top19"
          type="text"
          placeholder="输入手机号码"
          @input="phoneNum = phoneNum.replace(/[^\d]/g, '')"
          maxlength="11"
          :class="[errorType == 2 ? 'err_border' : '']"
          v-model.trim="phoneNum"
          @focus="clearErrType"
        />
      </div>
      <div class="login_code_box margin_top19">
        <div class="ico_input">
          <div style="top: 14px" class="ico_box">
            <i class="hy-icon-yanzhengma"></i>
          </div>
          <input
            class="input_text input_num"
            type="text"
            maxlength="6"
            placeholder="输入短信验证码"
            :class="[errorType == 3 ? 'err_border' : '']"
            v-model.trim="code"
            @focus="clearErrType"
          />
        </div>
        <div v-if="!msgCode" class="code_btn cursor_hover" @click="getMsgCode">
          发送验证码
        </div>
        <div v-if="msgCode" class="code_btn code_send_active">
          已发送({{ timer }}s)
        </div>
      </div>
      <div class="ico_input">
        <div class="ico_box">
          <i class="hy-icon-mima"></i>
        </div>
        <input
          class="input_text margin_top19"
          type="password"
          maxlength="20"
          autocomplete='off'
          placeholder="密码必须为8-16位字母大小写及数字组合"
          :class="[errorType == 4 ? 'err_border' : '']"
          v-model.trim="password"
          @focus="clearErrType"
        />
      </div>
      <div class="ico_input">
        <div class="ico_box">
          <i class="hy-icon-mima"></i>
        </div>
        <input
          class="input_text margin_top19"
          type="password"
          maxlength="20"
          autocomplete='off'
          placeholder="请再次输入登录密码"
          :class="[errorType == 5 ? 'err_border' : '']"
          v-model.trim="password_confirm"
          @focus="clearErrType"
        />
      </div>
      <div class="margin_top19 rule">
        <el-checkbox v-model="ruleRead"></el-checkbox>
        <span class="rule_text"
          >我已阅读并接受<i @click="toDocument">《用户协议和隐私条款》</i></span
        >
      </div>
      <div
        class="subimt_btn cursor_hover"
        :style="{
          background: ruleRead
            ? 'linear-gradient(90deg, #2079FB, #4CB2FC)'
            : '#dddddd',
        }"
        @click="subimtInfo"
      >
        注册
      </div>
      <div class="signup_bottom_text">
        <span class="display_flex">
          <i style="color: #666">已有账号，</i>
          <i style="color: #1c8eeb" class="cursor_hover" @click="toLogin"
            >直接登录</i
          >
        </span>
      </div>
    </div>
    <el-dialog
      title="用户协议和隐私政策"
      :visible.sync="user"
      width="1000px"
      :before-close="handleClose"
    >
      <div style="height: 500px; overflow-y: scroll">
        <div style="height: 1800px">
          本协议是您与新疆煤炭交易中心电商平台（简称"本平台"）所有者新疆煤炭交易中心有限公司之间就本平台服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击"同意并继续"按钮后，本协议即构成对双方有约束力的法律文件。
          &nbsp;<br /><br />第1条 本平台服务条款的确认和接纳 &nbsp;<br /><br />1.1本平台的各项在线服务的所有权和运作权归本平台所有。用户同意所有注册协议条款并完成注册程序，才能成为本平台的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
          &nbsp;<br /><br />1.2用户点击同意本协议，即视为用户确认自己具有享受在线交易煤炭及享受服务等相应的权利和行为能力，能够独立承担法律责任。
          &nbsp;<br /><br />1.3本平台保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。
          &nbsp;<br /><br />第2条 本平台服务 &nbsp;<br /><br />2.1本平台通过互联网依法为用户提供煤炭产品信息等服务，用户在完全同意本协议及本平台规定的情况下，方有权使用本平台的相关服务。
          &nbsp;<br /><br />第3条 用户信息 &nbsp;<br /><br />3.1用户应本着个人诚信向本平台提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且本平台保留终止用户使用本平台各项服务的权利。
          &nbsp;<br /><br />3.2用户在本平台进行浏览、下单等活动时，涉及用户及企业真实姓名/名称、通信地址、联系方式、营业执照等隐私信息的，本平台将予以严格保密，除非得到用户授权或法律另有规定，本平台不会向外界披露用户及企业隐私信息。
          &nbsp;<br /><br />3.3用户注册成功后，将产生用户名和密码等账户信息，您可以根据本平台规定修改您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本平台。
          &nbsp;<br /><br />3.4用户同意，本平台拥有通过邮件、短信电话等形式，向在本平台注册、交易用户、收货人发送订单信息、促销活动等告知信息的权利。
          &nbsp;<br /><br />3.5用户若将在本平台注册获得的账户借给他人使用，则必须承担由此产生的全部责任，且实际使用人需承担连带责任。
          &nbsp;<br /><br />3.6用户同意，本平台有权使用用户的注册信息、用户名、密码等信息，登陆进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。
          &nbsp;<br /><br />第4条 用户依法言行义务 &nbsp;<br /><br />本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：
          &nbsp;<br /><br />（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
          &nbsp;<br /><br />（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；
          &nbsp;<br /><br />（3）不得利用本平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
          &nbsp;<br /><br />（4）不得干扰本平台的正常运转，不得侵入本平台及国家计算机信息系统；
          &nbsp;<br /><br />（5）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
          &nbsp;<br /><br />（6）不得教唆他人从事本条款所禁止的行为； &nbsp;<br /><br />（7）不得利用在本平台注册的账户进行非法牟利经营活动；用户应不时关注并遵守本平台不时公布或修改的各类合法规则规定。
          &nbsp;<br /><br />本平台保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。
          &nbsp;<br /><br />若用户未遵守以上规定的，本平台有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。
          &nbsp;<br /><br />第5条 商品信息 &nbsp;<br /><br />5.1本平台上的产品价格、数量、是否有货等商品信息将根据市场行情及销售情况随时发生变动，本平台不作特别通知。由于网站产品信息数量庞大，虽然本平台会尽最大努力保证您所浏览商品信息的准确性，但由于网络及电脑终端兼容性等客观原因存在，本平台网页显示的信息可能会有一定的滞后性或差错，对此情形请您知悉并理解；本平台欢迎纠错，并会视情况给予纠错者一定的奖励。
          &nbsp;<br /><br />第6条 意向订单 &nbsp;<br /><br />6.1您在平台下单时，请您仔细确认所购产品的信息。若您提供的信息错误导致交易过程中出现利益损失的，则本平台将不承担相应法律责任及连带责任。
          &nbsp;<br /><br />6.2除法律另有强制性规定外，双方约定如下：本平台展示的商品和价格等信息仅仅是本平台中煤炭销售方的要约邀请，您下单时须填写您希望购买的商品数量、企业名称等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向电商平台的煤炭销售方发出的意向要约；电商平台的煤炭销售方收到您的订单信息并确认后，即视为您与煤炭销售方之间建立了线上意向订单关系；
          &nbsp;<br /><br />6.3由于市场变化及各种以合理商业努力难以控制的因素的影响，本平台无法保证您提交的订单信息中希望购买的商品都会有货；如您拟购买的商品，发生缺货，您有权取消订单。
          &nbsp;<br /><br />第7条 责任限制及不承诺担保 &nbsp;<br /><br />7.1除非另有明确的书面说明,本平台及其所包含的或以其它方式通过本平台提供给您的全部信息、资料、产品（包括软件）和服务，均是在"按现状"和"按现有"的基础上提供的。
          &nbsp;<br /><br />7.2除非另有明确的书面说明,本平台不对本平台的运营及其包含在本网站上的信息、资料、产品（包括软件）和服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
          &nbsp;<br /><br />如因不可抗力或其它本平台无法控制的原因使本平台销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，本平台会合理地尽力协助处理善后事宜。
          &nbsp;<br /><br />第8条 协议更新及用户关注义务 &nbsp;<br /><br />8.1根据国家法律法规变化及网站运营需要，本平台有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本平台上即生效，并代替原来的协议。用户可随时登陆查阅最新协议；用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受本平台依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。本平台建议您在使用本平台之前阅读本协议及本平台的公告。
          如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
          &nbsp;<br /><br />第9条 法律管辖和适用 &nbsp;<br /><br />9.1本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。
          如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。
          如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向本平台所在地法院提起诉讼。
          &nbsp;<br /><br />第10条 其他 &nbsp;<br /><br />10.1本平台所有者是指在政府部门依法许可或备案的本平台经营主体。
          &nbsp;<br /><br />10.2本平台尊重用户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本平台欢迎用户和社会各界提出意见和建议，本平台将虚心接受并适时修改本协议及本平台上的各类规则。
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { register, getsendSmsCode } from "@/api/home.js";
import md5 from "js-md5";
export default {
  data() {
    return {
      user: false,
      msgCode: false,
      timer: 60, //定义初始时间为60s

      ruleRead: false,
      errorType: 0,
      userName: "",
      phoneNum: "",
      code: "",
      password: "",
      password_confirm: "",
    };
  },
  methods: {
    handleClose() {
      this.user = false;
    },
    clearErrType() {
      this.errorType = 0;
    },
    ruleCheck() {
      this.ruleRead = !this.ruleRead;
    },
    //  注册信息
    subimtInfo() {
      // if(!this.ruleRead) return;

      if (!this.$verify.phone(this.phoneNum)) {
        this.errorType = 2;
        return this.$YTmsg("请填写正确的手机号", "error");
      }
      if (!this.$verify.code(this.code)) {
        this.errorType = 3;
        return this.$YTmsg("请填写正确的验证码", "error");
      }
      if (!this.$verify.password(this.password)) {
        this.errorType = 4;
        return this.$YTmsg("密码必须为8-16位字母大小写及数字组合", "error");
      }
      if (this.password_confirm != this.password) {
        this.$YTmsg("两次输入的密码不一致", "error");
        this.errorType = 5;
        return;
      }
      if (!this.ruleRead) {
        this.$YTmsg("请阅读并勾选页面协议", "error");
        this.errorType = 5;
        return;
      }
      this.errorType = 0;

      if (this.errorType) return;
      register({
        grant_type: "captcha",
        clientId: "xjcec",
        clientSecret: "xjcec_secret",
        scope: "all",
        telephone: this.phoneNum,
        password: md5(this.password),
        smsCode: this.code,
        againPassword: md5(this.password),
        userType: 2,
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success("注册成功请登录");
          this.code = "";
          this.password = "";
          this.$emit("toLogin", 1);
        } else {
          // window.localStorage.clear();
          this.$YTmsg(res.message || "注册失败", "warning");
        }
      });
    },
    //  微信登录
    WXLogin() {
      this.$emit("WXLogin");
    },
    toLogin() {
      this.$emit("toLogin", 1);
    },
    toDocument(index) {
      this.user = true;
      // let routeData = this.$router.resolve({
      //   path: index == 1 ? '/document/user' : '/document/privacy'
      // });
      // window.open(routeData.href, '_blank');
    },
    //    获取短信验证码
    async getMsgCode() {
      if (!this.$verify.phone(this.phoneNum)) {
        this.errorType = 2;
        return this.$YTmsg("请填写正确的手机号", "error");
      }

      //  注册验证码登录
      let res = await getsendSmsCode({ phone: this.phoneNum });

      if (res.code && res.code != 0) {
        return this.$YTmsg(res.message || "验证码发送失败", "error");
      }
      this.$YTmsg("验证码已发送,请注意查收", "success");

      if (this.msgCode) return;
      this.msgCode = true;

      let auth_timer = setInterval(() => {
        this.timer--; //递减时间
        if (this.timer <= 0) {
          this.msgCode = false;
          this.timer = 60;
          clearInterval(auth_timer);
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 2px;
}
.cursor_hover{
  cursor:pointer;
}
.ico_input {
  position: relative;
  .ico_box {
    position: absolute;
    top: 34px;
    left: 12px;
  }
}
.botton {
  position: absolute;
  left: 50%;
  margin: 20px;
  width: 80px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #000;
  text-align: center;
  transform: translateX(-50%);
}
.userdeal {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
  z-index: 999999;
  width: 1200px;
  height: 600px;
  overflow-y: auto;
  overflow-x: visible;
}
.signup_from {
  color: #333;
  font-size: 14px;
  i {
    font-style: normal;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px !important;
    font-weight: 400;
    color: #1c8eeb;
    font-size: 22px;
  }
  .rule {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .rule_check {
    color: #dddddd;
    cursor: pointer;
    padding-top: 3px;
  }
  .rule_check_active {
    color: #1c8eeb;
  }
  .rule_text {
    color: #333333;
    margin-left: 8px;
    i {
      color: #1c8eeb;
      cursor: pointer;
    }
  }

  .signup_bottom_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }
}

.input_text {
  width: 100%;
  height: 44px;
  border-radius: 2px;
  border: #dddddd 1px solid;
  padding-left: 38px;
  outline: none;
}
.input_num {
  width: 200px;
}

.margin_top19 {
  margin-top: 19px;
}
.login_code_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.code_btn {
  width: 126px;
  line-height: 46px;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  font-size: 14px;
  background: linear-gradient(90deg, #2079fb, #4cb2fc);
  border-radius: 2px;
}

.subimt_btn {
  width: 100%;
  line-height: 52px;
  background: linear-gradient(90deg, #2079fb, #4cb2fc);
  color: #fff;
  font-size: 16px;
  text-align: center;
  border-radius: 2px;
  margin-top: 22px !important;
}
.signup_now {
  text-align: center;
  margin-top: 24px;
  color: #1c8eeb;
  font-size: 14px;
}
.third_party_login {
  margin: 32px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    width: 110px;
    height: 1px;
    background-color: #ddd;
    margin-top: 1px;
  }
}
</style>